import * as React from 'react'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import './styles.scss'

const ContentDisclaimer = ({ content }) => {
    return (
        <div className="c-content-disclaimer">
            <small>{parse(content)}</small>
        </div>
    )
}

ContentDisclaimer.propTypes = {
    /**
     * Disclaimer content (mixed HTML)
     */
    content: PropTypes.string.isRequired
}

ContentDisclaimer.defaultProps = {
    content: '<p>Disclaimer content goes here</p>'
}

export default ContentDisclaimer
